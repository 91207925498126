import React from 'react';

import "./Events.css";

const BibleStudy = () => {
  return (
    <div class="EventsContainer">
      <div>This page is currently under construction.</div>
    </div>
  );
};

export default BibleStudy;
